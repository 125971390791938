import React from 'react'
import Classes from "./card.module.css"

const Card = ({style, children}) => {
    return (
        <div style={{...style}} className={Classes.cardCover}>
            {children}
        </div>
    )
}

export default Card
