import React, {useRef, useState, useEffect, useContext} from 'react';
import { MainContext } from '../../App';
import Subscription from './store';

const EventSubscription = ({itemsPerPage = '8'}) => {
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [ editShowModal, setEditShowModal] = useState(false)
  const refReactPaginate = useRef();
  const CTX = useContext(MainContext)

  const getAllSubscription = async () => {
    setLoading(true);
    const fetched = await fetch(`${CTX.url}/agent/stores`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${CTX.token}`,
      },
    });
    const json = await fetched.json();

    setLoading(false);
    setSubscription(json.data);


  };

  useEffect(() => {
    getAllSubscription();
  }, []);

  useEffect(() => {
    if (subscription.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = subscription.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(subscription.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, subscription]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % subscription.length;
    setItemOffset(newOffset);
  };

  return (
    <Subscription
      currentItems={currentItems}
      loading={loading}
      pageCount={pageCount}
      pageNum={pageNum}
      showModal={showModal}
      setShowModal={setShowModal}
      refReactPaginate={refReactPaginate}
      setEditShowModal={setEditShowModal}
      editShowModal={editShowModal}
      reGetStores={getAllSubscription}
    />
  );
};

export default EventSubscription;
