import React from 'react'
import Classes from "./eachPageHeader.module.css"
import {ReactComponent as Right} from "../../../asset/right.svg"

export const EachPageHeader = ({first, second, third}) => {
    return (
        <div className={Classes.eachPageHeaderCover}>
            <small>{first}</small>
            <Right fill="#7F7F7F" width="11px" />
            <small>{second}</small>
            <Right fill="#7F7F7F" width="11px" />
            <small>{third}</small>
        </div>
    )
}

export const PageHeader = ({name}) => (
    <h3 className={Classes.eachPageHeader}>{name}</h3>
)
