import React, {useState} from 'react';

import Classes from './login.module.css';
import SemiLogo from '../../asset/logo_semi.png';
import RightLogo from '../../asset/star.png';
import Logo from '../../asset/logo.png';
import Input from '../../component/input/input';
import Button from '../../component/button/button';
import {States} from '../../data/stateJson';
import {PaystackConsumer} from 'react-paystack';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';

const Login = ({
  inputs,
  setInputs,
  setSelectedStates,
  setRegister,
  register,
  onSubmitHandler,
  msg,
  loading,
  loginDetails,
  CTX,
  setMsg,
  setLoading,
  selectedStates,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onBlurPhoneHandler = (e) => {
    console.log('CTX => ', CTX);
    if (e.target.value.length !== 11) {
      setMsg('Invalid phone number');
      return;
    } else {
      setMsg('');
      return;
    }
  };
  // config files for paystack section
  const config = {
    reference: new Date().getTime().toString(),
    email: inputs?.email,
    // amount: +`${CTX.paystack.amount}00`,
    publicKey: 'pk_test_862f8eacb92c99b642b7fe34fdc5edc5b1d992ea',
    // plan: CTX.paystack.plan,
  };

  // when payment for paystack is done => then this handler
  const handleSuccess = async (reference) => {
    setLoading(true);
    try {
      // const fetched = await fetch(`${CTX.url}${CTX.loginDetails.url}`, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   method: 'POST',
      //   body: JSON.stringify({
      //     reference: reference.reference,
      //     email: inputs.email,
      //     first_name: inputs.first_name,
      //     phone_number: inputs.phone_number,
      //     store_name: inputs.store_name,
      //     last_name: inputs.last_name,
      //     state: selectedStates,
      //     terms: true,
      //     password: inputs.password,
      //   }),
      // });
      // const fetchJson = await fetched.json();
      // CTX.setPaystack({plan: '', amount: ''});
      // CTX.setLoginDetails({url: '/customer/register'});
      // setLoading(false);
      // setTimeout(() => {
      //   setMsg('');
      //   setInputs({email: '', password: '', first_name: '', last_name: ''});
      // }, 5000);
      // setMsg('Confirm your email address');
    } catch (error) {
      console.log('error after payment => ', error);
    }
  };

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed');
  };
  const componentProps = {
    ...config,
    text: 'Paystack Button Implementation',
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div className={Classes.LoginFormCover}>
       <Helmet>
        <title>Login - Passward Agent </title>
        <meta name="og:title" content="Login - Passward Agent" />
        <meta name="og:description" content="Login - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <div className={Classes.relative}>
        <nav className={Classes.nav}>
          <Link to="/" onClick={() => CTX.closeLogin()}>
            <img src={Logo} className={Classes.logo} alt="Passward Logo" />
          </Link>
        </nav>
        <h1 className={Classes.header}>{register ? 'Register' : 'Login'}</h1>
        <form
          autoComplete="off"
          className={Classes.formCover}
          onSubmit={onSubmitHandler}
        >
          {msg.length > 0 && (
            <div
              className={Classes.msgAlert}
              style={{textAlign: 'center', marginBottom: '40px'}}
            >
              <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                {msg}
              </p>
            </div>
          )}
          {register && (
            <div className={Classes.main_width}>
              <div className={Classes.gridTwo}>
                <Input
                  type="input"
                  required={true}
                  value={inputs?.first_name}
                  onChange={(e) =>
                    setInputs({...inputs, first_name: e.target.value})
                  }
                  labelStyle={{color: '#000033'}}
                  label="First Name"
                  style={{backgroundColor: '#fff', height: '50px'}}
                  placeholder="First Name"
                />

                <Input
                  type="input"
                  required={true}
                  labelStyle={{color: '#000033'}}
                  label="Last Name"
                  value={inputs?.last_name}
                  onChange={(e) =>
                    setInputs({...inputs, last_name: e.target.value})
                  }
                  placeholder="Last Name"
                  style={{backgroundColor: '#fff', height: '50px'}}
                />
              </div>

              <div className={Classes.gridTwo}>
                <Input
                  type="select"
                  required
                  style={{backgroundColor: '#fff', height: '50px'}}
                  labelStyle={{color: '#000033'}}
                  options={States.map((v) => {
                    return v.name;
                  })}
                  onChange={(e) => setSelectedStates(e.target.value)}
                  label="State"
                />

                <Input
                  type="input"
                  required={true}
                  onBlur={onBlurPhoneHandler}
                  labelStyle={{color: '#000033'}}
                  label="Phone Number"
                  value={inputs?.phone_number}
                  onChange={(e) =>
                    setInputs({...inputs, phone_number: e.target.value})
                  }
                  placeholder="Phone Number"
                  style={{backgroundColor: '#fff', height: '50px'}}
                />
              </div>
            </div>
          )}
          <div className={Classes.main_width}>
            <Input
              type="input"
              placeholder="yourmail@domain.com"
              inputType="email"
              value={inputs?.email}
              onChange={(e) => setInputs({...inputs, email: e.target.value})}
              required={true}
              labelStyle={{color: '#000033'}}
              label="Email"
              style={{backgroundColor: '#fff', height: '50px'}}
            />
          </div>
          {/* {register && (
            <div className={Classes.main_width} style={{marginTop: '8px'}}>
              <Input
                type="select"
                required
                style={{backgroundColor: '#fff', height: '50px'}}
                labelStyle={{color: '#000033'}}
                options={States.map((v) => {
                  return v.name;
                })}
                onChange={(e) => setSelectedStates(e.target.value)}
                label="State"
              />
            </div>
          )} */}
          <div className={Classes.main_width} style={{marginTop: '8px'}}>
            <Input
              type="input"
              required={true}
              value={inputs?.password}
              onChange={(e) => setInputs({...inputs, password: e.target.value})}
              labelStyle={{color: '#000033'}}
              placeholder="* * * * * * *"
              showPassword={() => setShowPassword(!showPassword)}
              inputType={showPassword ? 'text' : 'password'}
              label="Password"
              style={{backgroundColor: '#fff', height: '50px'}}
            />
          </div>
          {!register && (
            <div className={Classes.main_width} style={{marginTop: '8px'}}>
              <div>
                <input type="checkbox" className={Classes.inputCheckbox} />
                <label className={Classes.coloredLabel}>
                  Keep me Signed In
                </label>
              </div>
              <label
                className={Classes.coloredLabel}
                style={{marginLeft: 'auto', cursor: 'pointer'}}
              >
                Forgot Password?
              </label>
            </div>
          )}
          {/* {!register && ( */}
          <div className={Classes.main_width} style={{marginTop: '38px'}}>
            <Button
              text={register ? 'Register' : 'Login'}
              style={{width: '100%', height: '40px', marginBottom: '50px'}}
              loading={loading}
            />
          </div>
          {/* )} */}
        </form>
        <div className={Classes.centeredText}>
          {!register && (
            <span style={{cursor: 'pointer'}} onClick={setRegister}>
              Don’t have account?
              <span
                style={{
                  marginLeft: '5px',
                  color: '#FF0066',
                  textDecoration: 'underline',
                }}
              >
                Register
              </span>
            </span>
          )}

          {register && (
            <span
              style={{cursor: 'pointer'}}
              onClick={() => setRegister(!register)}
            >
              Already a User?
              <span
                style={{
                  marginLeft: '5px',
                  color: '#FF0066',
                  textDecoration: 'underline',
                }}
              >
                Login
              </span>
            </span>
          )}
        </div>
        <img src={SemiLogo} className={Classes.topRightLogo} />
        <img src={RightLogo} className={Classes.rightLogo} />
        <img src={RightLogo} className={Classes.leftLogo} />
        <img src={RightLogo} className={Classes.topLeftLogo} />
        <img src={SemiLogo} className={Classes.semiLogo} />
      </div>
    </div>
  );
};

export default Login;
