import React, {useContext, useEffect, useState} from 'react';

import Input from '../../component/input/input';
import {Modal} from '../../component/modal/modal';
import Classes from './store.module.css';
import Button from '../../component/button/button';
import {MainContext} from '../../App';
import {States} from '../../data/stateJson';
import axios from 'axios';

const ModalSection = ({
  showModal,
  setShowModal,
  editShowModal,
  setEditShowModal,
  reGetStores,
  clicked,
  clearClicked,
}) => {
  const CTX = useContext(MainContext);
  const [updateDetails, setUpdateDetails] = useState(false);
  const [inputs, setInputs] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState('Abia');
  const [msg, setMsg] = useState('');
  const [editInput, setEditInput] = useState({});

  //   useEffect(() => {
  //     if (!CTX.user.bank) {
  //       setUpdateDetails(true);
  //     } else {
  //       setUpdateDetails(false);
  //     }
  //   });

  const formOnSubmitHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const request = await axios({
        url: `${CTX.url}/agent/store/create`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          store_name: inputs.store_name,
          state,
          store_address: inputs.store_address,
          description: inputs.description,
          vendor_firstname: inputs.vendor_firstname,
          vendor_lastname: inputs.vendor_lastname,
          phone_number: inputs.phone_number,
          email: inputs.email,
        },
      });
      setShowModal(!showModal);
      setLoading(false);
      setMsg('');
      closeModal();
      reGetStores();
    } catch (error) {
      setMsg('Invalid Details');
      setLoading(false);
    }
  };

  const saveEditStore = async () => {
    try {
      setLoading(true);

      const request = await axios({
        url: `${CTX.url}/agent/store/update/${editInput.pk}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${CTX.token}`,
        },
        data: {
          name: editInput.name,
          state: editInput.state,
          address: editInput.address,
          description: editInput.description,
        },
      });
      setShowModal(!showModal);
      setLoading(false);
      setMsg('');
      closeModal();
      reGetStores();
    } catch (error) {
      setMsg('Invalid Details');
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setMsg('');
    setInputs({
      store_name: '',
      state: '',
      store_address: '',
      description: '',
      vendor_firstname: '',
      vendor_lastname: '',
      phone_number: '',
      email: '',
    });
    clearClicked({});
  };

  const closeEdit = () => {
    setEditShowModal(!editShowModal);
    clearClicked({});
    setEditInput({
      store_name: '',
      state: '',
      store_address: '',
      description: '',
      vendor_firstname: '',
      vendor_lastname: '',
      phone_number: '',
      email: '',
    });
  };

  console.log('editInput => ', editInput);

  useEffect(() => {
    setEditInput(clicked);
  }, [clicked]);

  console.log('clicked => ', clicked);
  console.log('clicked => ', clicked);

  return (
    <>
      <Modal top="50%" show={showModal} close={() => setShowModal(!showModal)}>
        <h3 className={Classes.confirmRemoval}>Add Store</h3>

        <div className={Classes.scrollDeep}>
          <form className={Classes.cover} onSubmit={formOnSubmitHandler}>
            {msg.length > 0 && (
              <div
                className={Classes.msgAlert}
                style={{textAlign: 'center', marginBottom: '40px'}}
              >
                <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
                  {msg}
                </p>
              </div>
            )}
            <Input
              label="Store Name"
              required
              type="input"
              placeholder="Store name"
              value={inputs?.store_name}
              onChange={(e) =>
                setInputs({...inputs, store_name: e.target.value})
              }
            />
            <div className={Classes.gridTwo}>
              <Input
                label="State"
                // style={{paddingLeft: '60px'}}
                required
                type="select"
                options={States.map((v) => v.name)}
                onChange={(e) => setState(e.target.value)}
              />

              <Input
                required
                label="Store Address"
                type="input"
                placeholder="Address"
                value={inputs?.store_address}
                onChange={(e) =>
                  setInputs({...inputs, store_address: e.target.value})
                }
              />
            </div>

            <Input
              required
              label="Description"
              type="textarea"
              txtPlaceholder="Product feature"
              value={inputs?.description}
              onChange={(e) =>
                setInputs({...inputs, description: e.target.value})
              }
            />

            <div className={Classes.ptag}>Vendor Details</div>
            <div className={Classes.gridTwo}>
              <Input
                label="First Name"
                required
                type="input"
                placeholder="Name"
                value={inputs?.vendor_firstname}
                onChange={(e) =>
                  setInputs({...inputs, vendor_firstname: e.target.value})
                }
              />

              <Input
                label="Last Name"
                required
                type="input"
                placeholder="Name"
                value={inputs?.vendor_lastname}
                onChange={(e) =>
                  setInputs({...inputs, vendor_lastname: e.target.value})
                }
              />

              <Input
                label="Email"
                // style={{paddingLeft: '60px'}}
                required
                type="input"
                inputType="email"
                value={inputs?.email}
                onChange={(e) => setInputs({...inputs, email: e.target.value})}
                placeholder="vendor@mail.com"
              />

              <Input
                required
                label="Phone Number"
                type="input"
                value={inputs?.phone_number}
                onChange={(e) =>
                  setInputs({...inputs, phone_number: e.target.value})
                }
                placeholder="00000000000"
              />
            </div>
            <div className={Classes.flexRight}>
              <Button
                text="Add Store"
                style={{height: '38px'}}
                loading={loading}
              />
              <Button
                text="Cancel"
                onClick={() => setShowModal(false)}
                style={{
                  marginLeft: '20px',
                  backgroundColor: '#fff',
                  color: '#ff0066',
                }}
              />
            </div>
          </form>
        </div>
      </Modal>
      <Modal top="50%" show={editShowModal} close={closeEdit}>
        <h3 className={Classes.confirmRemoval}>Edit Store</h3>

        {msg.length > 0 && (
          <div
            className={Classes.msgAlert}
            style={{textAlign: 'center', marginBottom: '40px'}}
          >
            <p style={{margin: '0px 0px'}} className={Classes.msgItself}>
              {msg}
            </p>
          </div>
        )}

        <Input
          label="Store Name"
          required
          type="input"
          placeholder="Store name"
          value={editInput?.name}
          onChange={(e) => setEditInput({...editInput, name: e.target.value})}
        />
        <div className={Classes.gridTwo}>
          <Input
            label="State"
            // style={{paddingLeft: '60px'}}
            required
            type="select"
            options={States.map((v) => v.name)}
            onChange={(e) => setState(e.target.value)}
            selected={editInput.state}
          />

          <Input
            required
            label="Store Address"
            type="input"
            placeholder="Address"
            value={editInput?.address}
            onChange={(e) => setEditInput({...editInput, address: e.target.value})}
          />
        </div>

        <Input
          required
          label="Description"
          type="textarea"
          txtPlaceholder="Product feature"
          value={editInput?.description}
          onChange={(e) =>
            setEditInput({...editInput, description: e.target.value})
          }
        />

        <div className={Classes.flexRight}>
          <Button
            text="Save Changes"
            style={{height: '38px', width: loading ? '160px' : '130px'}}
            loading={loading}
            onClick={saveEditStore}
          />
          <Button
            text="Cancel"
            onClick={() => setShowModal(false)}
            style={{
              width: '130px',
              marginLeft: '20px',
              backgroundColor: '#fff',
              color: '#ff0066',
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ModalSection;
