import React, {useState, useEffect, useRef} from 'react';
import EachOrder from './eachOrder';

const EventEachOrder = ({itemsPerPage="8"}) => {
  const [data, setData] = useState({
    order_status: false,
    name: 'Sopsop Deliveries',
    phone: '08106046040',
  });
  const orderData = [
    {
      img: 'https://www.acouplecooks.com/wp-content/uploads/2021/06/Strawberry-Water-006.jpg',
      store_name: 'acouplecooks',
      name: 'strawberry water',
      price: '2500',
      quantity: '2',
      subtotal: '5000',
    },
    {
      img: 'https://www.acouplecooks.com/wp-content/uploads/2020/07/Iced-Tea-001-735x919.jpg',
      store_name: 'acouplecooks',
      name: 'iced tea',
      price: '2500',
      quantity: '2',
      subtotal: '5000',
    },
    {
      img: 'https://www.acouplecooks.com/wp-content/uploads/2020/12/Pomegranate-Juice-016s-735x919.jpg',
      store_name: 'acouplecooks',
      name: 'pomegranate juice',
      price: '2500',
      quantity: '2',
      subtotal: '5000',
    },
    {
      img: 'https://www.acouplecooks.com/wp-content/uploads/2013/06/Kombucha-Recipe-005-735x919.jpg',
      store_name: 'acouplecooks',
      name: 'kombucha',
      price: '2500',
      quantity: '2',
      subtotal: '5000',
    },
    {
      img: 'https://www.acouplecooks.com/wp-content/uploads/2021/03/Lime-Rickey-003-735x919.jpg',
      store_name: 'acouplecooks',
      name: 'lime rickey',
      price: '2500',
      quantity: '2',
      subtotal: '5000',
    },
  ];
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();

  const getAllOrder = () => {
    setTimeout(() => {
      setOrders(orderData);
      setLoading(false);
    }, 4000);
    setLoading(true);
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    if (orders.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = orders.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(orders.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };

  return <EachOrder data={data}
  currentItems={currentItems}
  pageCount={pageCount}
  pageNum={pageNum}
  loading={loading}
  handlePageClick={handlePageClick}
  refReactPaginate={refReactPaginate}
  orders={orders}
  />;
};

export default EventEachOrder;
