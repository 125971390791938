import React, {Suspense, useContext, useState} from 'react';
import {
  EachPageHeader,
} from '../../component/layout/eachPageHeader/eachPageHeader';
import Classes from './customer.module.css';
import Sidebar from '../../component/layout/sidebar/sidebar';
import {MainContext} from '../../App';
import Button from '../../component/button/button';
import {AiOutlineSearch} from 'react-icons/ai';
import {Modal} from '../../component/modal/modal';

import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LazyLoading from '../../component/lazyLoading/lazyLoading';

import ReactPaginate from 'react-paginate';
import {ReactComponent as Left} from '../../asset/left.svg';
import {Helmet} from 'react-helmet';

const Customer = ({
  loading,
  showModal,
  setShowModal,
  refReactPaginate,
  sendMessageHandler,
  editorState,
  setEditorState,
  handlePageClick,
  pageNum,
  pageCount,
  currentItems,
}) => {
  const CTX = useContext(MainContext);

  const mapMyOrders = currentItems.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Name: </strong>User Full Name
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Email: </strong>User Email
        here
      </div>

      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Number of Orders: </strong>4
      </div>

      <div className={Classes.myOrderItemCover}>₦5000</div>

      <div className={Classes.myOrderItemCover}>Enabled</div>
    </div>
  ));

  const mapLoading = Array(7)
    .fill('')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
         <Skeleton width="100%" height="100%" />
      </div>
    ));

  const wrapperStyle = {
    border: '1px solid #969696',
  };
  const editorStyle = {
    height: '10rem',
    padding: '1rem',
  };

  return (
    <div className={Classes.flexSection}>
       <Helmet>
        <title>Customers - Passward Agent </title>
        <meta name="og:title" content="Customers - Passward Agent" />
        <meta name="og:description" content="Customers - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
        <SkeletonTheme baseColor="red" highlightColor="#444">
          <div className={Classes.myOrdersCover}>
            <EachPageHeader first="Home" second="Account" third="Customers" />

            <div className={Classes.subNavCover}>
              <form className={Classes.firstForm}>
                <input
                  placeholder="Search  Customer"
                  className={Classes.searchFormSection}
                />
                <button className={Classes.buttonFormSection}>
                  <AiOutlineSearch fill="#fff" size="1.3em" />
                </button>
              </form>

              <div className={Classes.PageHeaderCover}>
                <h3 className={Classes.eachPageHeader}>Customers</h3>
                <div className={Classes.sideEachPageHeader}>
                  <form className={Classes.secondForm}>
                    <input
                      placeholder="Search  Customer"
                      className={Classes.searchFormSection}
                    />
                    <button className={Classes.buttonFormSection}>
                      <AiOutlineSearch fill="#fff" size="1.3em" />
                    </button>
      
                  </form>
                  <Button
                    onClick={() => setShowModal(true)}
                    text="Message Customers"
                    style={{height: 'max-content', marginTop: '0px'}}
                  />
                </div>
              </div>
            </div>

            <div className={Classes.cardCover}>
              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  <div className={Classes.cardHeader}>
                    <strong style={{marginRight: 'auto'}}>Name</strong>
                    <strong style={{marginRight: 'auto'}}>Email Address</strong>
                    <strong>No. of Orders</strong>
                    <strong>Revenue</strong>
                    <strong>Notification</strong>
                  </div>

                  {loading ? mapLoading : mapMyOrders}
                </div>
              </div>
            </div>
          </div>
          </SkeletonTheme>
        </Suspense>
        <div style={{display: 'flex', marginBottom: '30px'}}>
          <div className={Classes.showingCover}>
            Showing {pageNum} of {pageCount}
          </div>

          <ReactPaginate
            ref={refReactPaginate}
            breakLabel="..."
            nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={<Left />}
            className={Classes.paginationClass}
            renderOnZeroPageCount={null}
            disabledClassName={Classes.disabledClassName}
          />
        </div>
      </div>
      <Modal show={showModal} close={() => setShowModal(!showModal)}>
        <h3 className={Classes.confirmRemoval}>Message Customers</h3>

        <Editor
          editorState={editorState}
          // toolbarClassName={Classes.toolbarClassName}
          // wrapperClassName={Classes.wrapperClassName}
          // editorClassName={Classes.editorClassName}
          wrapperClassName={Classes.wrapper_class}
          wrapperStyle={wrapperStyle}
          editorStyle={editorStyle}
          // toolbarStyle={toolbarStyle}
          editorClassName={Classes.demo_editor}
          onEditorStateChange={(e) => setEditorState(e)}
        />
        <div className={Classes.flexRight}>
      
          <Button
            text="Send Message"
            loading={loading}
            showLoading={false}
            onClick={sendMessageHandler}
          />
          <Button
            text="Cancel Message"
            onClick={() => setShowModal(false)}
            style={{
              marginLeft: '20px',
              backgroundColor: '#fff',
              color: '#ff0066',
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Customer;
