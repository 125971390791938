import React, {useState, useEffect, useRef} from 'react';
import Products from './withdrawal';

const EventProducts = ({itemsPerPage = '10'}) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [inputs, setInputs] = useState({});
  const [category, setCategory] = useState([]);
  const refReactPaginate = useRef();

  const data = [
    {
      img: 'https://m.media-amazon.com/images/I/61Z1+6XhWwL._AC_SL1500_.jpg',
      name: 'modern clock',
      category: 'fashion and fashion',
      price: '4500',
      date: '12/10/2022',
      status: true,
    },
    {
      img: 'https://m.media-amazon.com/images/I/61Z1+6XhWwL._AC_SL1500_.jpg',
      name: 'modern clock',
      category: 'fashion and fashion',
      price: '4500',
      date: '12/10/2022',
      status: true,
    },
  ];

  const getAllProducts = () => {
    setTimeout(() => {
      setProducts(data);
      setLoading(false);
    }, 4000);
    setLoading(true);
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (products.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = products.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(products.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, products]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  return (
    <Products
      showModal={showModal}
      setShowModal={setShowModal}
      loading={loading}
      handlePageClick={handlePageClick}
      pageCount={pageCount}
      currentItems={currentItems}
      products={products}
      pageNum={pageNum}
      refReactPaginate={refReactPaginate}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
      inputs={inputs}
      category={category}
      setInputs={setInputs}
      setCategory={setCategory}
    />
  );
};

export default EventProducts;
