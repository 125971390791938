import React, {useContext, useEffect} from 'react';
import Plot from 'react-plotly.js';
import Classes from './dashboard.module.css';
import {
  EachPageHeader,
  PageHeader,
} from '../../component/layout/eachPageHeader/eachPageHeader';
import {Helmet} from 'react-helmet';
import Sidebar from '../../component/layout/sidebar/sidebar';
import {MainContext} from '../../App';
import DashboardCard from '../../component/dashboardCard/dashboardCard';
// import {ReactComponent as Money} from '../../asset/money.svg';
import {ReactComponent as Store} from '../../asset/store.svg';
// import {ReactComponent as Box} from '../../asset/box.svg';
import {ReactComponent as Bag} from '../../asset/bag.svg';
import RecentOrder from './recentOrder';
import { AiOutlineWallet } from 'react-icons/ai';
import { GetUserStatistics } from '../../helper/getUserStatistics';

const Home = () => {
  const CTX = useContext(MainContext);
  const {data, getSta} = GetUserStatistics();

  useEffect(() => {
    const sad = getSta()
    if(data.balance){
      CTX.setStats(data)
    }
  }, [])

  
  // console.log("data => ", data)
  

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Overview - Passward Agent </title>
        <meta name="og:title" content="Overview - Passward Agent" />
        <meta name="og:description" content="Settings - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <EachPageHeader first="Home" second="Account" third="Overview" />
        <PageHeader name="Overview" />
        <div className={Classes.gridDashboardCard}>
          <DashboardCard label="Wallet Balance" num={"₦" + " " + data.balance} svg={<AiOutlineWallet />} />
          <DashboardCard label="Stores" num={data.stores} svg={<Store width="16px" />} />
          <DashboardCard label="Orders" num={data.order} svg={<Bag />} />
        </div>

        <div className={Classes.firstGraph}>
          <div className={Classes.headerSection}>
            <strong>Wallet Balance</strong>
            <select>
              <option>2022</option>
              <option>2022</option>
              <option>2022</option>
              <option>2022</option>
            </select>
          </div>
          <Plot
            data={[
              {
                x: [1, 2, 3],
                y: [2, 6, 3],
                mode: 'lines',
                marker: {color: '#969696'},
              },
            ]}
            className={Classes.theSVG}
            style={{width: '100%', height: '100%'}}
            layout={{
              responsive: true,
              useResizeHandler: true,
              autosize: true,
              title: '',
              xaxis: {
                showgrid: true,
              },
              yaxis: {
                showgrid: true,
                autotick: false,
                ticks: 'outside',
                tick0: 0,
                // dtick: 1.25,
                ticklen: 28,
                tickwidth: 4,
                tickcolor: '#e9eaf9',
              },
            }}
            config={{displayModeBar: false}}
          />
        </div>

        <RecentOrder />
      </div>
    </div>
  );
};

export default Home;
