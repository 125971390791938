import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../App';
import axios from 'axios';
// import Loading from '../components/loading/loading';

export const GetUserStatistics = () => {
  const CTX = useContext(MainContext);
  const [loadingStatistics, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getSta = async () => {
    try {
      const request = await axios.get(`${CTX.url}/agent/dashboard/statistics`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Token ${sessionStorage.getItem('a_tk')}`,
        },
      });
      setLoading(false);
      sessionStorage.setItem("stats", JSON.stringify(request.data.data))
      setData(request.data.data)
      // console.log("statistic request.data.data => ", request.data.data)
    } catch (error) {
      setLoading(false);
      // GetStatistics()
      // setMsg('Invalid login details');
      console.log(error);
    }
  };


  // useEffect(() => {
  //     GetStatistics()
  // }, [])


  return {loadingStatistics, data, getSta};
};


// HD2020/07289/1/CS