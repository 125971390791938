import React from 'react';
import {AiOutlineClose} from 'react-icons/ai';

import {Modal} from '../../component/modal/modal';
import Input from '../../component/input/input';
import Button from '../../component/button/button';
import Classes from './withdrawalSettings.module.css';

const ChangePass = ({
  show,
  close,
  showAgent,
  closeAgent,
  showImage,
  closeImage,
}) => {
  return (
    <>
      <Modal show={show} close={close}>
        <div className={Classes.headerSection}>
          <strong>Change Password</strong>
        </div>
        <div
          style={{paddingBottom: '0px'}}
          className={Classes.subHeaderSection}
        >
          <Input
            required
            placeholder="Existing Password"
            label="Existing Password"
            type="input"
            inputType="password"
          />

          <div style={{display: 'flex'}}>
            <Input
              required
              placeholder="New Password"
              label="New Password"
              type="input"
              inputType="password"
            />
            <div style={{width: '30px'}}> </div>
            <Input
              required
              placeholder="Confirm Password"
              label="Confirm Password"
              type="input"
              inputType="password"
            />
          </div>
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{marginLeft: 'auto', display: 'flex'}}>
            <Button bgColor="#ff0166" text="Save Password" />
            <div style={{width: '20px'}}> </div>
            {/* <Button bgColor="#6c757d" text="Cancel" /> */}
            <Button
              text="Cancel"
              onClick={close}
              style={{
                marginLeft: '20px',
                backgroundColor: '#fff',
                color: '#ff0066',
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal show={showAgent} close={closeAgent}>
        <div className={Classes.headerSection}>
          <strong>Request Agent Change</strong>
        </div>
        <div
          style={{paddingBottom: '0px'}}
          className={Classes.subHeaderSection}
        >
          <Input
            txtPlaceholder=" "
            label="Add a reason for the request:"
            type="textarea"
            inputType="password"
          />
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{marginLeft: 'auto', display: 'flex'}}>
            {/* <Button bgColor="#ff0166" text="Save Password" /> */}
            <div style={{width: '20px'}}> </div>
            <Button bgColor="#6c757d" text="Yes, Request" />
          </div>
        </div>
      </Modal>

      <Modal show={showImage} close={closeImage}>
        <div className={Classes.headerSection}>
          <strong>Change Profile Image</strong>
        </div>
        <div
          style={{paddingBottom: '0px'}}
          className={Classes.subHeaderSection}
        >
          <Input
            txtPlaceholder=" "
            label="Select an image"
            type="input"
            accept="image/*"
            inputType="file"
          />
        </div>

        <div className={Classes.bottomBorder}>
          <div style={{marginLeft: 'auto', display: 'flex'}}>
            {/* <Button bgColor="#ff0166" text="Save Password" /> */}
            {/* <div style={{width: '20px'}}> </div> */}
            <Button bgColor="#6c757d" text="Change" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangePass;
