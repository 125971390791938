import React from 'react';
import Classes from './dashboardCard.module.css';

const DashboardCard = ({svg, label, num}) => {
  return (
    <div className={Classes.DashboardCardCover}>
      <div className={Classes.svgCover}>{svg}</div>
      <div className={Classes.textCoverSection}>
        <p style={{margin: '0px 0px  5px 0px', fontSize: "13px"}}>{label}</p>
        <strong style={{fontSize: "13px"}}>{num}</strong>
      </div>
    </div>
  );
};

export default DashboardCard;
