import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Classes from './modal.module.css';

  
  export const Modal = ({show, children, close, showClose=true, top="40%" }) => {
  return (
    <>
      <BGround show={show} close={close} />
      <div style={{display:  show ?"block": "none", top: top}} className={Classes.mainModal} >
        {showClose && <AiOutlineClose className={Classes.AiOutlineClose} onClick={close} />}
        {children}
        
        </div>
    </>
  );
};

export const BGround = ({show, close}) => {
  return <>{show && <div onClick={close} className={Classes.mainBackgroundCover}></div>}</>;
};
