import React, {Suspense, useContext} from 'react';
import {AiOutlineSearch} from 'react-icons/ai';
import ReactPaginate from 'react-paginate';

import Classes from './orders.module.css';
import Sidebar from '../../component/layout/sidebar/sidebar';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import {EachPageHeader} from '../../component/layout/eachPageHeader/eachPageHeader';
import {MainContext} from '../../App';
import {ReactComponent as Left} from '../../asset/left.svg';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {Helmet} from 'react-helmet';


const Orders = ({
  pageNum = {pageNum},
  refReactPaginate = {refReactPaginate},
  handlePageClick = {handlePageClick},
  pageCount = {pageCount},
  loading={loading},
  currentItems = {currentItems},
}) => {
  const CTX = useContext(MainContext);

  const mappedOrders = currentItems.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Order ID: </strong>
        {v.id}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Products: </strong>
        {v.products}
      </div>
      <div className={Classes.myOrderItemCover}>{v.date}</div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Status: </strong>
        {v.payment_status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
        )}
      </div>
      <div className={Classes.myOrderItemCover}>₦{v.total}</div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Method: </strong>
        {v.payment_method}
      </div>

      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Order Status: </strong>
        {v.order_status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
        )}
      </div>
      <div className={Classes.myOrderItemCover}>
        <Link to={`/my-orders/${v.id}`}>
          <button className={Classes.viewBTN}>View</button>
        </Link>
      </div>
    </div>
  ));

  const mappedLoading = Array(6)
    .fill('s')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
       <Helmet>
        <title>Orders - Passward Agent </title>
        <meta name="og:title" content="Orders - Passward Agent" />
        <meta name="og:description" content="Orders - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Account" third="Orders" />
          <div className={Classes.subNavCover}>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Orders</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search Order"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                {/* <Button
                  onClick={() => {setShowModal(true); setIsEdit(false)}}
                  text="Add New Product"
                  style={{height: 'max-content', marginTop: '0px'}}
                /> */}
              </div>
            </div>
          </div>

          {/* main orderSection */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                <div className={Classes.cardHeader}>
                  <strong style={{marginRight: 'auto'}}>Order ID</strong>
                  <strong style={{marginRight: 'auto'}}>Products</strong>
                  <strong style={{marginRight: 'auto'}}>Date</strong>
                  <strong>Payment Status</strong>
                  <strong>Total</strong>
                  <strong>Payment Method</strong>
                  <strong>Order Status</strong>
                  <strong>Action</strong>
                </div>
                {loading ? mappedLoading : mappedOrders}
              </div>
            </div>
          </div>

          <div style={{display: 'flex', marginBottom: '30px', width: '100%'}}>
            <div className={Classes.showingCover}>
              Showing {pageNum} of {pageCount}
            </div>

            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default Orders;
