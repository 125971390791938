import React, {useState} from 'react';
import Input from '../../component/input/input';
import {Modal} from '../../component/modal/modal';
import Classes from './withdrawal.module.css';
import Button from '../../component/button/button';

const ModalSection = ({
  sendMessageHandler,
  loading,
  showModal,
  setShowModal,
  isEdit,
}) => {
  const [value, setValue] = useState(['', '', '', '', '', '', '', '', '', '']);
  const [ref] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const [enterPhone, setEnterPhone] = useState(false);
  const [OTP, setOTP] = useState(false);
  const formOnSubmitHandler = () => {};

  const onChangeValue = (newValue, index) => {
    if (newValue.match(/(\d|^(?![\s\S]))/g) && newValue.length <= 2) {
      setValue([
        ...value.slice(0, index),
        newValue.slice(-1),
        ...value.slice(index + 1),
      ]);
      if (newValue.length > 0 && ref[index + 1]) {
        ref[index + 1].focus();
      }
    }
  };

  const onRemove = (key, index) => {
    if (key === 'Backspace') {
      ref[index - 1].focus();
    }
  };

  return (
    <Modal top="50%" show={showModal} close={() => setShowModal(!showModal)}>
      <h3 className={Classes.confirmRemoval}>
        {enterPhone ? 'Request OTP' : 'Withdraw Fund'}
      </h3>

      {/* <form className={Classes.cover} onSubmit={formOnSubmitHandler}> */}
      {!enterPhone && (
        <div className={Classes.amountCover}>
          <div className={Classes.nairaCover}>₦</div>
          <Input
            label="Amount"
            style={{paddingLeft: '60px'}}
            required
            marb={true}
            type="input"
            inputType="number"
          />
        </div>
      )}
      {!OTP && (
        <>
          {enterPhone && (
            <>
              <div
                className={Classes.emptyArrayCover}
                style={{height: '69px', marginTop: '0px', marginBottom: '14px'}}
              >
                <small>We will send a 6 digit code to you via SMS</small>
              </div>
              <Input
                label="Phone Number"
                required
                // marb={true}
                type="input"
                inputType="number"
                placeholder="Enter phone number"
              />
            </>
          )}
        </>
      )}
      {OTP && (
        <div>
          <div
            className={Classes.emptyArrayCover}
            style={{height: '69px', marginTop: '0px', marginBottom: '14px'}}
          >
            <small>Enter the 6 digit code we sent to you via SMS</small>
          </div>
          <div className={Classes.allInputCover}>
            <input
              ref={(input) => {
                ref[1] = input;
              }}
              value={value[1]}
              onChange={({target}) => onChangeValue(target.value, 1)}
              onKeyUp={({key}) => onRemove(key, 1)}
            />
            <input
              ref={(input) => {
                ref[2] = input;
              }}
              value={value[2]}
              onChange={({target}) => onChangeValue(target.value, 2)}
              onKeyUp={({key}) => onRemove(key, 2)}
            />
            <input
              ref={(input) => {
                ref[3] = input;
              }}
              value={value[3]}
              onChange={({target}) => onChangeValue(target.value, 3)}
              onKeyUp={({key}) => onRemove(key, 3)}
            />
            <input
              ref={(input) => {
                ref[4] = input;
              }}
              value={value[4]}
              onChange={({target}) => onChangeValue(target.value, 4)}
              onKeyUp={({key}) => onRemove(key, 4)}
            />
            <input
              ref={(input) => {
                ref[5] = input;
              }}
              value={value[5]}
              onChange={({target}) => onChangeValue(target.value, 5)}
              onKeyUp={({key}) => onRemove(key, 5)}
            />
            <input
              ref={(input) => {
                ref[6] = input;
              }}
              value={value[6]}
              onChange={({target}) => onChangeValue(target.value, 6)}
              onKeyUp={({key}) => onRemove(key, 6)}
            />
          </div>
        </div>
      )}

      <div className={Classes.flexRight}>
        {enterPhone ? (
          <Button
            text={OTP ? 'Submit OTP' : 'Submit Request'}
            loading={loading}
            showLoading={false}
            onClick={() => setOTP(true)}
          />
        ) : (
          <Button
            text="Request OTP"
            loading={loading}
            showLoading={false}
            onClick={() => setEnterPhone(!enterPhone)}
          />
        )}
        <Button
          text="Cancel"
          onClick={() => {
            setShowModal(false);
            setEnterPhone(false);
            setOTP(false);
          }}
          style={{
            marginLeft: '20px',
            backgroundColor: '#fff',
            color: '#ff0066',
          }}
        />
      </div>
      {/* </form> */}
    </Modal>
  );
};

export default ModalSection;
