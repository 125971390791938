import React from 'react';
import Classes from './input.module.css';
import {ReactComponent as Star} from '../../asset/_.svg';
import {ReactComponent as Eye} from '../../asset/eye.svg';

const Input = ({
  selected,
  readOnlyMsg = '',
  showPassword,
  label,
  inputType,
  onChange,
  value,
  type,
  options = [],
  style,
  labelStyle,
  onBlur,
  placeholder = '',
  txtPlaceholder = 'Short description of store',
  required,
  readOnly,
  accept,
  disabled,
  rows="3",
  marb=false
}) => {
  return (
    <div className={Classes.mainCover} style={{marginBottom: marb ? "0px": "1rem"}}>
      <label style={labelStyle} className={Classes.label} htmlFor={label}>
        {label}
        {required && (
          <span style={{marginLeft: '4px'}}>
            <Star />
          </span>
        )}
      </label>
      {label == 'Password' && (
        <Eye onClick={showPassword} className={Classes.EyeSVG} />
      )}
      {type === 'input' && (
        <input
          autoComplete="nope"
          autoComplete="off"
          readOnly={readOnly}
          accept={accept}
          onBlur={onBlur}
          required={required}
          placeholder={placeholder}
          className={Classes.inputCover}
          style={{
              backgroundColor: readOnly ? '#f9f9f9' : 'inherit',
              color: readOnly ? '#969696' : '#6c757d',
              ...style,
          }}
          value={value}
          onChange={onChange}
          type={inputType}
        />
      )}
      {type === 'select' && (
        <select
          className={Classes.inputCover}
          style={style}
          onChange={onChange}
        >
          {options.map((v, i) => (
            <option
              selected={selected === v && selected}
              key={(v, i)}
              value={v}
            >
              {v}
            </option>
          ))}
        </select>
      )}
      {type === 'textarea' && (
        <textarea
          required={required}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={txtPlaceholder}
          style={{height: 'auto'}}
          onChange={onChange}
          value={value}
          className={Classes.inputCover}
          rows={rows}
        ></textarea>
      )}
      {readOnlyMsg.length > 3 && (
        <small className={Classes.readOnlyMsg}>{readOnlyMsg}</small>
      )}
    </div>
  );
};

export default Input;
