import React, {Suspense, useContext} from 'react';
import ReactPaginate from 'react-paginate';
import {useParams} from 'react-router-dom';
import {AiOutlineSearch} from 'react-icons/ai';

import Classes from './eachOrder.module.css';
import {EachPageHeader} from '../../component/layout/eachPageHeader/eachPageHeader';
import {MainContext} from '../../App';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import Sidebar from '../../component/layout/sidebar/sidebar';
import DashboardCard from '../../component/dashboardCard/dashboardCard';
import {ReactComponent as Money} from '../../asset/money.svg';
import {ReactComponent as Store} from '../../asset/store.svg';
import {ReactComponent as Box} from '../../asset/box.svg';
import {ReactComponent as Bag} from '../../asset/bag.svg';
import {ReactComponent as Left} from '../../asset/left.svg';
import Skeleton from 'react-loading-skeleton'
import {Helmet} from 'react-helmet';

const EachOrder = ({
  data,
  currentItems,
  pageCount,
  pageNum,
  loading,
  handlePageClick,
  refReactPaginate,
  orders,
}) => {
  const CTX = useContext(MainContext);
  const params = useParams();

  const mapMyOrders = orders.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <img src={v.img} className={Classes.myOrderItemImage} alt="item" />
        <p>
          <small>From {v.store_name} stores</small>
          <strong style={{display: 'block', marginTop: '2px'}}>{v.name}</strong>
        </p>
      </div>
      <div>
        <span>₦{v.price}</span>
      </div>
      <div>
        <span>{v.quantity}</span>
      </div>
      <div>
        <span>₦{v.subtotal}</span>
      </div>
      <div>
        <button className={Classes.removeBtn}>Remove Item</button>
      </div>
    </div>
  ));


  const mappedLoading = Array(4)
  .fill('s')
  .map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <Skeleton width="100%" height="100%" />
    </div>
  ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Orders - Passward Agent </title>
        <meta name="og:title" content="Orders - Passward Agent" />
        <meta name="og:description" content="Orders - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Account" third="Orders" />
          <div className={Classes.gridDashboardCard}>
            <DashboardCard label="Total Revenue" num="N0.00" svg={<Money />} />
            <DashboardCard label="Orders" num="4" svg={<Bag />} />
            <DashboardCard label="Products" num="2" svg={<Store />} />
            <DashboardCard label="Customers" num="2" svg={<Box width="16px" />} />
          </div>

          <div className={Classes.orderDetailsCover}>
            <div className={Classes.orderStatus}>
              <strong style={{marginRight: '6px'}}>Order Status:</strong>
              {data.order_status ? (
                <button className={Classes.trueVisibilityCover}>paid</button>
              ) : (
                <button
                  style={{backgroundColor: '#ff6251'}}
                  className={Classes.trueVisibilityCover}
                >
                  pending
                </button>
              )}
            </div>

            <div className={Classes.companyDetails}>
              <strong style={{marginBottom: '10px'}}>
                Logistics Company Details
              </strong>
              <small style={{marginBottom: '5px'}}> Name: {data.name}</small>
              <small>Phone: {data.phone}</small>
            </div>
          </div>

          <div className={Classes.subNavCover}>
            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Order ID: {params.id}</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search Order"
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* main order sectioon where the orders are mapped */}
          <div className={Classes.cardCover}>
            <div className={Classes.mappedStoresCover}>
              <div className={Classes.cardListingCover}>
                <div className={Classes.cardHeader}>
                  <strong style={{marginRight: 'auto'}}>Item</strong>
                  <strong>Unit Price</strong>
                  <strong>Quantity</strong>
                  <strong>Subtotal</strong>
                  <strong></strong>
                </div>

                {loading? mappedLoading : mapMyOrders}
              </div>
            </div>
          </div>

          {/* order total */}
          <div className={Classes.totalCover}>
            <strong>Total:</strong>
            <strong
              style={{color: '#000033', fontSize: '22px', marginLeft: '15px'}}
            >
              ₦24998
            </strong>
          </div>

          {/* pagination section */}
          <div style={{display: 'flex', marginBottom: '50px', width: '100%'}}>
            <div className={Classes.showingCover}>
              Showing {pageNum} of {pageCount}
            </div>

            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default EachOrder;
