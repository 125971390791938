import React, {useContext, useEffect, useState} from 'react';
import Classes from './dashboard.module.css';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom';
import { MainContext } from '../../App';
import axios from 'axios';

const RecentOrder = () => {
  const [loading, setLoading] = useState(false);
  const [incomeHistory, setIncomeHistory] = useState([])
  const CTX = useContext(MainContext)
  const orders = [
    {
      id: 'w324e24f342r',
      products: '2',
      date: '12/06/2022',
      payment_status: false,
      total: '7300',
      payment_method: 'credit card',
      order_status: false,
    },
    {
      id: '32edwr23rwe',
      products: '2',
      date: '12/06/2022',
      payment_status: true,
      total: '7300',
      payment_method: 'credit card',
      order_status: false,
    },
    {
      id: '23ewde3r4454t',
      products: '2',
      date: '12/06/2022',
      payment_status: false,
      total: '7300',
      payment_method: 'credit card',
      order_status: true,
    },
  ];

  const income = [
    {name: 'Tonimyk Stores', signed: 'nil', date: '12/02/2022', amount: '5000'},
    {
      name: 'legendary Stores',
      signed: 'nil',
      date: '12/02/2022',
      amount: '5000',
    },
  ];


  const getIncomeHistory =  async () => {
    try {
      const request = await axios.get(`${CTX.url}/agent/dashboard/percentage/history`, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Token ${sessionStorage.getItem('a_tk')}`,
        },
      });
      setLoading(false);
      // setIncomeHistory(request.data.data)
      console.log("statistic request.data.data => ", request.data.data)
    } catch (error) {
      // setLoading(false);
      // GetStatistics()
      // setMsg('Invalid login details');
      console.log(error);
    } 
  }
  



  useEffect(() => {
    getIncomeHistory()
  },[])



  const mappedOrders = orders.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Order ID: </strong>
        {v.id}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Products: </strong>
        {v.products}
      </div>
      <div className={Classes.myOrderItemCover}>{v.date}</div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Status: </strong>
        {v.payment_status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
        )}
      </div>
      <div className={Classes.myOrderItemCover}>₦{v.total}</div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Payment Method: </strong>
        {v.payment_method}
      </div>

      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Order Status: </strong>
        {v.order_status ? (
          <button className={Classes.trueVisibilityCover}>paid</button>
        ) : (
          <button
            style={{backgroundColor: '#ff6251'}}
            className={Classes.trueVisibilityCover}
          >
            pending
          </button>
        )}
      </div>
      <div className={Classes.myOrderItemCover}>
        <Link to={`/my-orders/${v.id}`}>
          <button className={Classes.viewBTN}>View</button>
        </Link>
      </div>
    </div>
  ));

  const mappedLoading = Array(3)
    .fill('s')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  const mappedIncome = incomeHistory.map((v, i) => (
    <div
      key={i}
      className={Classes.mappedOrders}
      style={{gridTemplateColumns: "1fr 0.4fr 0.6fr 0.6fr"}}
    >
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Store Name: </strong>
        {v.name}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Signed: </strong>
        {v.signed}
      </div>
      <div className={Classes.myOrderItemCover}>
        {v.date}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Amount: </strong>
        {v.amount}
      </div>
    </div>
  ));

  return (
    <>
      <div className={Classes.RecentOrderCover}>
        <div style={{position: 'relative'}} className={Classes.headerSection}>
          <strong>Recent Orders</strong>
          <select style={{width: '100px'}}>
            <option>February</option>
            <option>March</option>
            <option>April</option>
            <option>May</option>
          </select>
        </div>
        <div className={Classes.cardHeader}>
          <strong style={{marginRight: 'auto'}}>Order ID</strong>
          <strong style={{marginRight: 'auto'}}>Products</strong>
          <strong style={{marginRight: 'auto'}}>Date</strong>
          <strong>Payment Status</strong>
          <strong>Total</strong>
          <strong>Payment Method</strong>
          <strong>Order Status</strong>
          <strong>Action</strong>
        </div>
        {loading ? mappedLoading : mappedOrders}
      </div>

      <div className={Classes.RecentOrderCover} style={{marginBottom: "40px"}}>
        <div style={{position: 'relative'}} className={Classes.headerSection}>
          <strong>Recent Income</strong>
          {/* <select style={{width: '100px'}}>
            <option>February</option>
            <option>March</option>
            <option>April</option>
            <option>May</option>
          </select> */}
        </div>
        <div
          style={{gridTemplateColumns: '1fr 0.4fr 0.6fr 0.6fr'}}
          className={Classes.cardHeader}
        >
          <strong style={{marginRight: 'auto'}}>Store Name</strong>
          <strong style={{marginRight: 'auto'}}>Signed</strong>
          <strong style={{marginRight: 'auto'}}>Date</strong>
          <strong>Amount</strong>
        </div>
        {loading ? mappedLoading: mappedIncome.length < 1 ? <div className={Classes.noIncome}>You have no income history </div> : mappedIncome}
        {/* <h1>loadings</h1> */}
      </div>
    </>
  );
};

export default RecentOrder;
