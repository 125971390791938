import React, {useState, useRef, useEffect} from 'react';
import Orders from './orders';

const EventOrders = ({itemsPerPage = '8'}) => {
  const data = [
    {
      id: 'w324e24f342r',
      products: '2',
      date: '12/06/2022',
      payment_status: false,
      total: '7300',
      payment_method: 'credit card',
      order_status: false,
    },
    {
      id: '32edwr23rwe',
      products: '2',
      date: '12/06/2022',
      payment_status: true,
      total: '7300',
      payment_method: 'credit card',
      order_status: false,
    },
    {
      id: '23ewde3r4454t',
      products: '2',
      date: '12/06/2022',
      payment_status: false,
      total: '7300',
      payment_method: 'credit card',
      order_status: true,
    },
    {
      id: '32rewt43t5re43',
      products: '2',
      date: '12/06/2022',
      payment_status: true,
      total: '7300',
      payment_method: 'credit card',
      order_status: true,
    },
  ];
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const refReactPaginate = useRef();

  const getAllOrder = () => {
    setTimeout(() => {
      setOrders(data);
      setLoading(false);
    }, 4000);
    setLoading(true);
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    if (orders.length < 1) return;
    // Fetch items from another resources.carts
    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading carts from ${itemOffset} to ${endOffset}`);
    const allContent = orders.slice(itemOffset, endOffset);
    const eachPageItem = allContent.splice(0, itemsPerPage);
    //  setCurrentItems(items.slice(itemOffset, endOffset));
    setCurrentItems(eachPageItem);
    setPageCount(Math.ceil(orders.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPageNum(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };

  return (
    <Orders
      orders={orders}
      loading={loading}
      refReactPaginate={refReactPaginate}
      currentItems={currentItems}
      pageCount={pageCount}
      pageNum={pageNum}
      handlePageClick={handlePageClick}
    />
  );
};

export default EventOrders;
