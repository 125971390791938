import React, {Suspense, useContext, useState} from 'react';
import Classes from './store.module.css';
import Sidebar from '../../component/layout/sidebar/sidebar';
import LazyLoading from '../../component/lazyLoading/lazyLoading';
import {MainContext} from '../../App';
import {EachPageHeader} from '../../component/layout/eachPageHeader/eachPageHeader';
import {AiOutlineSearch} from 'react-icons/ai';
import Button from '../../component/button/button';
import Skeleton from 'react-loading-skeleton';

import ReactPaginate from 'react-paginate';
import {ReactComponent as Left} from '../../asset/left.svg';
import ModalSection from './modalSection';
import {Helmet} from 'react-helmet';
import {ReactComponent as Edit} from '../../asset/edit.svg';

const Subscription = ({
  setShowModal,
  showModal,
  setEditShowModal,
  editShowModal,
  currentItems,
  loading,
  pageNum,
  pageCount,
  refReactPaginate,
  handlePageClick,
  reGetStores,
}) => {
  const CTX = useContext(MainContext);
  const [clicked, setClicked] = useState({})

  const mappedSub = currentItems.map((v, i) => (
    <div key={i} className={Classes.mappedOrders}>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}> Store Name: </strong>
        {v.name}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>Vendor Name: </strong>
        {v.name}
      </div>
      <div className={Classes.myOrderItemCover}>
        <strong className={Classes.strongSection}>State: </strong>
        {v.state}
      </div>
      
      <div className={Classes.myOrderItemCover}>
        {v.activation_date.split('T1')[0]}
      </div>
      <div className={Classes.myOrderItemCover}>₦{v.revenue}</div>
      
      

      <div className={Classes.myOrderItemCover}>
        <p
          onClick={() => setEditShowModal(true)}
          className={Classes.actionBTNCover}
          style={{cursor: 'pointer'}}
        >
          <Edit onClick={() => setClicked(v)} />
        </p>
      </div>

      {/* </div> */}
    </div>
  ));

  const mappedLoading = Array(5)
    .fill('a')
    .map((v, i) => (
      <div key={i} className={Classes.mappedOrders}>
        <Skeleton width="100%" height="100%" />
      </div>
    ));

  return (
    <div className={Classes.flexSection}>
      <Helmet>
        <title>Subscription - Passward Agent </title>
        <meta name="og:title" content="Subscription - Passward Agent" />
        <meta name="og:description" content="Subscription - Passward Agent" />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
        <meta
          name="og:name"
          content="Passward is Nigeria's No.1 Online Shopping Destination with the largest collection of electronics, smartphones, groceries, computers, fashion, jewelry & more. ✓ Top brands at the best prices ✓ Enjoy cash on delivery on eligible purchases."
        />
      </Helmet>
      <Sidebar />
      <div
        onClick={CTX.removeSideBar}
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          width: '100%',
          filter: 'blur(var(--body-blur,0px))',
        }}
      >
        <Suspense fallback={<LazyLoading />}>
          {/* nav section here is just the top header side */}
          <EachPageHeader first="Home" second="Account" third="Stores" />
          <div className={Classes.subNavCover}>
            <form className={Classes.firstForm}>
              <input
                placeholder="Search..."
                className={Classes.searchFormSection}
              />
              <button className={Classes.buttonFormSection}>
                <AiOutlineSearch fill="#fff" size="1.3em" />
              </button>
            </form>

            <div className={Classes.PageHeaderCover}>
              <h3 className={Classes.eachPageHeader}>Stores</h3>
              <div className={Classes.sideEachPageHeader}>
                <form className={Classes.secondForm}>
                  <input
                    placeholder="Search..."
                    className={Classes.searchFormSection}
                  />
                  <button className={Classes.buttonFormSection}>
                    <AiOutlineSearch fill="#fff" size="1.3em" />
                  </button>
                </form>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  text="Add Store"
                  style={{height: 'max-content', marginTop: '0px'}}
                />
              </div>
            </div>
          </div>

          {loading && (
            <div className={Classes.cardCover}>
              <div className={Classes.mappedStoresCover}>
                <div className={Classes.cardListingCover}>
                  <div className={Classes.cardHeader}>
                    <strong>Store Name</strong>
                    <strong>Vendor</strong>
                    <strong>State</strong>
                    <strong>Date</strong>
                    <strong>Balance</strong>
                    <strong>Action</strong>
                  </div>
                  {mappedLoading}
                </div>
              </div>
            </div>
          )}


{loading ? <> </> :<>
         {/* here is the listing of all the products with the headers */}
         {!mappedSub.length < 1 && (
           <div className={Classes.cardCover}>
             <div className={Classes.mappedStoresCover}>
               <div className={Classes.cardListingCover}>
                 <div className={Classes.cardHeader}>
                   <strong>Store Name</strong>
                   <strong>Vendor</strong>
                   <strong>State</strong>
                   <strong>Date</strong>
                   <strong>Balance</strong>
                   <strong>Action</strong>
                 </div>

                 {mappedSub}
               </div>
             </div>
           </div>
         )}
</> 
}

          {!loading && (
            <>
              {mappedSub.length < 1 && (
                <div className={Classes.msgCover}>
                  You have not made any subscription
                </div>
              )}
            </>
          )}

          <div style={{display: 'flex', marginBottom: '30px', width: '100%'}}>
            <div className={Classes.showingCover}>
              Showing {pageNum} of {pageCount}
            </div>

            <ReactPaginate
              ref={refReactPaginate}
              breakLabel="..."
              nextLabel={<Left style={{transform: 'rotate(180deg)'}} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<Left />}
              className={Classes.paginationClass}
              renderOnZeroPageCount={null}
              disabledClassName={Classes.disabledClassName}
            />
          </div>
        </Suspense>
      </div>
      <ModalSection
        showModal={showModal}
        setShowModal={setShowModal}
        editShowModal={editShowModal}
        setEditShowModal={setEditShowModal}
        reGetStores={reGetStores}
        clicked={clicked}
        clearClicked={() => setClicked({})}
      />
    </div>
  );
};

export default Subscription;
